<template>
  <div class="me-wrapper-box">
    <div class="me-wrapper">
      <div class="me-banner">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img"><img :src="categoryInfo.head_image" /></div>
      </div>
      <div class="jk-banner">
        <ul>
          <li class="_img" v-for="item of categoryList" @click="jumpCategoryList(item)"  >
            <img :src="item.icon"/>
          </li>
        </ul>
      </div>
      <div class="jk-foot">
        <div class="_img"><img :src="categoryInfo.foot_image" /></div>
      </div>
    </div>
    <Footer :step="2" />
  </div>
</template>

<script>
import Footer from './components/footer'
export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      id :"",
      categoryInfo :{},
      categoryList:[]
    };
  },

  created() {
    this.id = this.$route.query.id;
  },

  mounted() {
    this.getCategoryDetail();
    this.getSubCategoryList();
  },

  methods: {
    jumpCategoryList(item){
        this.$router.push({path:'/category',query:{id:item.id}})
    },
    async getCategoryDetail() {
      //获取分类的详情
      let {data} = await this.$ajax.gateway("/api/api_get_category_by_id", {
        "METHOD": "get",
        id: this.id
      });
      this.categoryInfo = data.info;
    },
    async getSubCategoryList(){
      // 获取分类列表
      let {data} = await this.$ajax.gateway("/api/api_get_category_list", {
        "METHOD": "post",
        "body": {
          "parent_id": this.id
        }
      });
      this.categoryList = data;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../status/css/page.less";

.jk-banner {
  padding: 0 0.5rem;
  ul li {
    margin-top: 0.2rem;
  }
}
.jk-foot {
  width: 100%;
  padding: 0.6rem 0 0.3rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
